import tswift from "./assets/tswift.png"

// create a react component that renders the public/tswift.png image
function Taylor() {
  return (
    <div id="taylor-div">
      <img src={tswift} alt="taylor swift" id="taylor" />
    </div>
  );
}

export default Taylor;
