import './App.css';
// import the Taylor component from src/taylor.js
import Taylor from './taylor';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';

function Greeting(){
  const { name } = useParams();
  const userName = name ? name : "Madeline";
  return (
    <div className="App">
      <h1 id="name">Hi {userName}!</h1>
      <Taylor />
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route exact path="/" Component={Greeting} />
          <Route path="/:name" Component={Greeting} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
